import React, { useEffect, useState, useContext } from 'react';
import Navbar from '../../components/navbar/Navbar.jsx';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { decodeEntities } from '@wordpress/html-entities';
import '../questions/questions.css';
import Footer from '../../containers/footer/Footer.jsx';
import Lives from '../../assets/lives.png'
import Coin from '../../assets/coin.png'
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/authContext.js';
import PlayerImage from '../../components/playerimage/playerimage.js';


function CustomQuestions() {
  const location = useLocation();
  const category = location.state;

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [lives, setLives] = useState(3);
  const [correctanswers, setCorrectAnswers] = useState(0);
  const [score, setScore] = useState(0);
  const [color, setColor] = useState();
  const [quizStarted, setQuizStarted] = useState(false);

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const [err, setError] = useState(null);

  var completingquiz = false;
  const [finalpoints, setFinalpoints] = useState(0);
  var bonus = 0;
  const [totalquestions, settotalquestions] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: 'smooth',
    });
    if (category !== '') {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://api.quiztopolis.com/api/quizes/getquestions', { params: { category: category } });
          const fetchedQuestions = response.data.map((question) => {
            return {
              ...question,
              question: decodeEntities(question.question), // Decode the question
              correct_answer: decodeEntities(question.correct_answer), // Decode the correct answer
              incorrect_answers: JSON.parse(question.incorrect_answers).map((answer) => decodeEntities(answer)) // Decode the incorrect answers
            };
          }) || [];

          setQuestions(fetchedQuestions);
          settotalquestions(fetchedQuestions.length);

          const allAnswers = fetchedQuestions.map((question) => {
            const incorrectAnswers = question.incorrect_answers;
            const correctAnswer = question.correct_answer;
            const answerset = [...incorrectAnswers, correctAnswer];
            answerset.sort(function (a, b) {
              return Math.random() - 0.5;
            });
            return [...answerset];
          });

          setAnswers(allAnswers);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData(); // Call the function when the component mounts
    }
  }, [category]);

  useEffect(() => {
    if (questions.length > 0) {
      if (questions[0].difficulty === "easy") {
        setColor("#40A2E3");
      } else if (questions[0].difficulty === "medium") {
        setColor("#FC6736");
      } else {
        setColor("#B31312");
      }
    }

  }, [questions]);

  function updateQuestions() {
    const updatedQuestions = [...questions];
    const updatedAnswers = [...answers]
    updatedQuestions.shift();
    updatedAnswers.shift();
    setQuestions(updatedQuestions);
    setAnswers(updatedAnswers);
  }

  const completequiz = async (newScore, finishedQuiz) => {
    if (currentUser) {
      try {
        if (finishedQuiz) {
          const res = await axios.post("https://api.quiztopolis.com/api/users/quizcompletion", { id: currentUser.id, quiz_category: category });
          if (res.data === "User completed this quiz for the first time.") {
            updateScore(currentUser.score + newScore + 100);
            updateUserScoreDisplay(currentUser.score + newScore + 100);
          }
        } else {
          if (newScore > currentUser.score) {
            updateScore(newScore);
            updateUserScoreDisplay(newScore);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

  }

  function handleAnswerClick(answer) {
    let newScore = score;
    let newlives = lives;
    let bonus = 0;

    if (answer === questions[0].correct_answer) {
      if (questions[0].difficulty === "easy") {
        newScore += 10;
      } else if (questions[0].difficulty === "medium") {
        newScore += 20;
      } else {
        newScore += 30;
      }
      setCorrectAnswers(correctanswers + 1);
      setScore(newScore);
      updateQuestions();
      if (newScore > 0 && questions.length === 1) {
        completequiz(newScore, true);
      }
    } else {
      newlives -= 1;
      setLives(newlives);
      if (newScore > 0 && newlives > 0 && questions.length === 1) {
        completequiz(newScore, true);
      } else if ((newScore > score) && newlives < 1) {
        completequiz(newScore, false);
      }
      updateQuestions();
    }
  };

  function updateUserScoreDisplay(newscore) {
    if (currentUser.level === 1 && newscore > (currentUser.level * 100 * currentUser.level)) {
      const updatedUser = { ...currentUser, level: 2, score: newscore };
      setCurrentUser(updatedUser);
    } else if (newscore > (currentUser.level * 100 * currentUser.level)) {
      const updatedUser = { ...currentUser, level: (Math.floor(Math.sqrt(newscore / 100))), score: newscore };
      setCurrentUser(updatedUser);
    } else {
      const updatedUser = { ...currentUser, score: (newscore) };
      setCurrentUser(updatedUser);
    }

  }

  const updateScore = async (playerscore) => {
    if (playerscore > currentUser.score) {
      try {
        const res = await axios.post("https://api.quiztopolis.com/api/users/updatescore", { username: currentUser.username, score: playerscore });
      } catch (err) {
        setError(err.response.data);
      }
    }
  }

  function gotohomepage() {
    navigate("/");
  }

  function startQuiz() {
    setQuizStarted(true);
  }

  return (
    <>
      <div className='gradient__bg'>
        <Navbar />
      </div>
      {!currentUser && !quizStarted &&
        <div className='quiztopolis__questions-question quiztopolis__questions-lose section__padding'>
          <h1>It seems you're not logged in or don't have an account.</h1>
          <p>By subscribing, you can keep track of your scores and keep unlocking new levels.</p>
          <button className='quiztopolis__questions-btn' onClick={startQuiz}>Start Quiz</button>
        </div>
      }

      {(quizStarted || currentUser) &&
        <>
          {(lives > 0 && questions.length > 0) &&
            <div className='quiztopolis__questions section__padding'>
              {currentUser ?
                <>
                  <div className='quiztopolis__questions-user_total_score'>
                    <p>{currentUser?.username} Total Score: {currentUser?.score}</p>
                  </div>
                  <div className='quiztopolis__questions-player-image'><p>Level: </p><PlayerImage /></div>
                </>

                :
                null
              }
              <div className='quiztopolis__questions-status'>
                <div className='quiztopolis__questions-lives'>
                  <img src={Lives} alt="lives" />
                  <p>{lives}</p>
                </div>
                <div className='quiztopolis__questions-difficulty'>
                  <h1>Difficulty:</h1>
                  <h2 style={{ color }}>{questions.length > 0 ? questions[0].difficulty : null}</h2>
                </div>
                <div className='quiztopolis__questions-score'>
                  <img src={Coin} alt="lives" />
                  <p>{score}</p>
                </div>
              </div>
              <div className='quiztopolis__questions-question'>
                {/* add image here */}
                {questions[0].image ? 
                <img src={questions[0].image} alt="img" />:
                null
                }
                
                <h1>{questions.length > 0 ? questions[0].question : "Loading..."}</h1>
              </div>
              <div className='quiztopolis__questions-answers' id='answers'>
                {answers.length > 0 ? answers[0].map((answer, index) => (
                  <button className='quiztopolis__questions-btn' key={index} onClick={() => handleAnswerClick(answer)}>{answer}</button>
                )) : null}
              </div>
              <div className='quiztopolis__questions-info'>
                <div>
                  <p><img src={Lives} alt="lives" /> Info:</p>
                  <p>You start with 3 lives, for each wrong answer you lose 1 life, reaching 0 lives its Game Over.</p>
                </div>
                <div>
                  <p><img src={Coin} alt="lives" /> Info:</p>
                  <p>Easy Questions: 10 Points</p>
                  <p>Medium Questions: 20 Points</p>
                  <p>Hard Questions: 30 Points</p>
                </div>
              </div>
            </div>
          }

          {lives <= 0 &&
            <div className='quiztopolis__questions-lose section__padding'>
              <h1>Sorry, you didn't win this time. But don't worry, you can always try again.</h1>
              <p>Your score was: {score}</p>
              <p>{correctanswers} out of {totalquestions}</p>
              <Link to='/'><button className='quiztopolis__questions-btn' onClick={gotohomepage}>New Quiz</button></Link>
            </div>
          }

          {questions.length === 0 && score > 0 ?
            <div className='quiztopolis__questions-lose section__padding'>
              <h1>Well done!</h1>
              <p>Your score was: {score}</p>
              <p>{correctanswers} out of {totalquestions}</p>
              <Link to='/'><button className='quiztopolis__questions-btn' onClick={gotohomepage}>New Quiz</button></Link>
            </div> :
            questions.length === 0 &&
            <div className='quiztopolis__questions-question quiztopolis__questions-lose section__padding'>
              <h1>Loading...</h1>
            </div>
          }
        </>
      }
      <Footer />
    </>
  )

}

export default CustomQuestions;